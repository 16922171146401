import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "styling"
    }}>{`Styling`}</h1>
    <p>{`has support for`}</p>
    <ul>
      <li parentName="ul">{`css modules (import xxx.module.css)`}</li>
      <li parentName="ul">{`sass/scss modules (import xxx.module.scss, sass)`}</li>
      <li parentName="ul">{`global css (import xxx.css)`}</li>
      <li parentName="ul">{`global scss (import xxx.scss, sass)`}</li>
      <li parentName="ul">{`postcss is ran on all of them (before sass)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      